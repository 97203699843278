import { useEffect, useState } from 'react';

const useIsChrome = () => {
  const [isChrome, setIsChrome] = useState(false);

  function detectBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Edge") != -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  useEffect(() => {
    const browser = detectBrowser()
    if (browser == 'Chrome') setIsChrome(true)
    else setIsChrome(false)
  }, []);

  return isChrome;
};

export default useIsChrome;

