import React, { useEffect, useState } from "react";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import "../assets/css/welcome.css";
import "../assets/css/style.css";
import "../assets/css/font-size.css";
import "../assets/css/font-family.css";
import TableRegistration from "../components/welcome/TableRegistration";
import SplashScreen from "../components/welcome/SplashScreen";
import ModalInterface from "../common/ModalInterface";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessFlorrPlans, getBusinessInfo, setTableLoading } from "../store/actions/tableActions";
import { getPromotion } from "../store/actions/promtionsActions";
import { ChangeTakeawayStatus, getTakeAwayMenu, menuAction } from "../store/actions/menuAction";
import { getBestSeller } from "../store/actions/bestsellerAction";
import WelcomeModal from "../components/modals/welcomeModal";

const ImageRepository = RepositoryFactory.get("img");

const Welcome = () => {
  const dispatch = useDispatch();

  const store = useSelector((state) => state.table.business_Info);
  const loadingScreen = useSelector((state) => state.table.loading);

  const [notificationEnabled, setNotificationEnabled] = useState(true);
  const [pickupWithoutNotif, setPickupWithoutNotif] = useState(false)
  const [showWrapper, setShowWrapper] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [actionType, setActionType] = useState(undefined);
  const [fixURL, setFixURL] = useState(true);
  let [queryValue] = useSearchParams();

  let table_no = queryValue.get("table_no");
  let buid = queryValue.get("buid");
  let takeAwayStatus = queryValue.get("takeaway");
  const FloorPlanRepo = RepositoryFactory.get("floor_plans");
  let searchParams = window.location.search;
  let validateQuery = searchParams.includes("buid") && searchParams.includes("table_no");


  useEffect(() => {
    if (
      searchParams.length >= 0 &&
      validateQuery &&
      table_no !== "" &&
      String(table_no) !== "null" &&
      String(table_no) !== "undefined" &&
      table_no.length !== 0 &&
      String(buid) !== "null" &&
      buid.length !== 0 &&
      String(buid) !== "undefined" &&
      buid !== ""
    ) {
      dispatch(setTableLoading(true));
      setFixURL(false);
      dispatch({
        type: "LOG_OUT"
      });
      if (takeAwayStatus === "true") {
        dispatch(ChangeTakeawayStatus(takeAwayStatus));
        dispatch(getTakeAwayMenu(buid));
      } else {
        dispatch(menuAction(buid));
        store.best_seller === true && dispatch(getBestSeller(buid));
      }
      dispatch(getBusinessFlorrPlans(buid));
      dispatch(getBusinessInfo({ table_no, buid }));
      dispatch(getPromotion(buid));

      setTimeout(() => {
        // hide wrapper
        setShowWrapper(false);
      }, 7000);
      // load logo
      ImageRepository.getLogo().then(() => { });
    } else {
      dispatch(setTableLoading(false));
      setFixURL(true);
    }
  }, [searchParams]);


  const [selectedType, setSelectedType] = useState("");
  const handleSelectType = (type) => {
    setSelectedType(type);
  };
  useEffect(() => {
    if (buid && table_no) {
      FloorPlanRepo.getFloorPlanByNumber(buid, table_no).then(
        (response) => {
          setSelectedType(response?.data.data[0]?.table_type)
          if (response?.data.data[0]?.table_type === "Self-service") {
            setModalOpen(true);
          } else {
            setModalOpen(false);
          }
        }
      )
        .catch((error) => {
          console.error("Error fetching floor plan:", error);
        });
    }
  }, [buid, table_no, searchParams])

  const handleSaveChanges = () => {
    if (selectedType === "Self-service") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };


  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  useEffect(() => {
    if (selectedType === "Self-service") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [searchParams]);

  return loadingScreen ? (
    <div className="position-relative min-vh-100 w-100">
      <span
        className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  ) : (
    <>
      {fixURL && (
        <ModalInterface
          fullscreen={true}
          display={true}
          title={<div className="text-center text-danger">Fail!</div>}
          content={<div className="text-dark text-center">Please enter correct URL</div>}
        />
      )}

      {store.animation ? (
        <SplashScreen mainLogo={store.business_logo} showWrapper={showWrapper} />
      ) : null}
      {modalOpen ? (

        <WelcomeModal
          fullscreen={true}
          display={true}
          title={
            <div className="text-center text-danger"
              style={{
                paddingTop: '1rem',
                display: 'flex',
                flexDirection: 'column',
                justifyConent: 'center',
                alignItems: 'center',
              }}
            >
              <span>Wahle dein bevorzugte</span>
              <span>Serviceeinstellung</span>
            </div>}
          handleClose={handleClose}
          onSelectType={handleSelectType}
          onSubmit={handleSaveChanges}
          bodyStyles={{ width: '100%', direction: 'flex', justifyContent: 'center', alignItems: "center" }}
          notificationEnabled={notificationEnabled}
          setNotificationEnabled={setNotificationEnabled}
          pickupWithoutNotif={pickupWithoutNotif}
          setPickupWithoutNotif={setPickupWithoutNotif}
        />

      ) : null
      }
      <div className={`${store.animation} ? 'afterAnimation bg-white : bg-white' `}>
        <div className="logo">
          <div className="mainLogo">
            <img
              src={store.business_logo}
              alt="main_logo"
              width="85%"
              style={{ maxWidth: "280px", verticalAlign: "middle" }}
            />
          </div>
        </div>

        <main className="bgpinkish d-flex justify-content-center  w-100vh">
          {
            <TableRegistration
              actionType={actionType}
              setActionType={setActionType}
              submitting={submitting}
              setSubmitting={setSubmitting}
              tableType={selectedType}
              notificationEnabled={notificationEnabled}
              setNotificationEnabled={setNotificationEnabled}
              pickupWithoutNotif={pickupWithoutNotif}
              setPickupWithoutNotif={setPickupWithoutNotif}
            />
          }
        </main>
      </div>
    </>
  );
};

export default Welcome;
