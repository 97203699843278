/* eslint-disable no-unused-vars */

import startsWith from 'lodash.startswith';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WELCOME_ACTION_TYPE } from "../../common/Constants";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { orderInfo, setTable, tableID } from "../../store/actions/tableActions";
import ModalInterface from "../../common/ModalInterface";
import { requestPermission } from "../../lib/utils";
import { FormControlLabel, Switch, styled } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import useSafariDetection from '../../hooks/useBrowserDetection';
import useIsSafari from '../../hooks/useSafariDetection';

// import { Col, Row } from "react-bootstrap";
const NotifSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const TableRegistration = ({
  submitting,
  setSubmitting,
  actionType,
  setActionType,
  tableType,
  notificationEnabled,
  setNotificationEnabled,
  pickupWithoutNotif,
  setPickupWithoutNotif
}) => {
  const [erroralertMsg, ShowerroralertMsg] = useState(false);
  const [errorMsgResponce, setErrorMsgResponce] = useState();
  const [tableName, setTableName] = useState("");
  const [tableNameNotFilled, setTableNameNotFilled] = useState(false);
  const [UseAlphabets, setUseAlphabets] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState(false);
  const [showPeople, setShowPeople] = useState(false);
  const [showCapacity, setShowCapacity] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState()
  // const [notificationEnabled, setNotificationEnabled] = useState(true);
  // const [resultPermission,setResultPermission]=useState(null)
  const [showSwitch, setShowSwitch] = useState(false);

  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.welcome
  );

  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let table_no = searchParams.get("table_no");
  let busLocID = searchParams.get("buid");
  const joinTable = async () => {
    setSubmitting(true);
    setActionType(WELCOME_ACTION_TYPE.JOIN_TABLE);
    // const resultPermission= await requestPermission();
    let resultPermission = {}

    if (!isSafari) {
      resultPermission = await requestPermission();
      console.log("__resultPermission*********", resultPermission);
    }
    UserRepository.checkUser(tableName, table_no, busLocID, resultPermission, phoneNumber)
      .then((table) => {
        dispatch(tableID({ id: table.data[0]._id }));
        localStorage.setItem("table", JSON.stringify(table));
        setSubmitting(false);
        dispatch(setTable(tableName));
        dispatch(orderInfo({ businessLocationId: busLocID, tableNumber: table_no }));
        sessionStorage.setItem("session_type", "join");

        // TODO: redirect to Menu screen
        navigate("/home");
      })
      .catch((error) => {
        console.log(error.response);
        setErrorMsgResponce("DIESER TISCHNAME EXISTIERT NOCH NICHT");

        ShowerroralertMsg(true);
        setSubmitting(false);
      });
  };


  // const store = useSelector((state) => state.table.business_Info);
  const dispatch = useDispatch();

  const UserRepository = RepositoryFactory.get("user");
  const FloorPlanRepo = RepositoryFactory.get("floor_plans");
  useEffect(() => {
    sessionStorage.removeItem("reloadCount");
    sessionStorage.removeItem("reloadCount1");
    sessionStorage.clear();
    // localStorage.clear();
    if (
      table_no !== "" &&
      String(table_no) !== "null" &&
      String(table_no) !== "undefined" &&
      table_no.length !== 0 &&
      String(busLocID) !== "null" &&
      busLocID.length !== 0 &&
      String(busLocID) !== "undefined" &&
      busLocID !== ""
    ) {
      sessionStorage.setItem("blId", busLocID);
      localStorage.setItem("buid", busLocID);
      localStorage.setItem("table_no", table_no);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tableName) {
      setTableNameNotFilled(true);
      setShowPeople(false);
      return;
    }
    let resultPermission = {}

    if (!isSafari) {
      // if (notificationEnabled) {
      resultPermission = await requestPermission();
      console.log("__resultPermission*********", resultPermission);
      // }
    }
    setTableNameNotFilled(false);
    setSubmitting(true);
    setActionType(WELCOME_ACTION_TYPE.CREATE_TABLE);
    console.log("resultPermission", resultPermission);

    UserRepository.createTable(tableName, table_no, busLocID, resultPermission, phoneNumber, notificationEnabled, tableType)
      .then((user) => {
        dispatch(tableID({ id: user?.data?._id }));
        localStorage.setItem("user", JSON.stringify(user.data.table_name));
        sessionStorage.setItem("session_type", "create");
        setSubmitting(false);
        dispatch(setTable(user.data.table_name));
        dispatch(
          orderInfo({
            businessLocationId: busLocID,
            tableNumber: table_no,
            clientCount: numberOfPeople
          })
        );
        // TODO: redirect to Menu screen
        navigate("/home");
      })
      .catch((error) => {
        setShowCapacity(error?.response?.data?.message);

        if (error?.response?.status == 409) {
          setSubmitting(false);
          setIsErrorMessage(true);

          // return joinTable();
        } else {
          setErrorMsgResponce(error?.response?.data?.message);
          ShowerroralertMsg(true);

          setSubmitting(false);
        }
      });
  };

  const handleTableNameChange = (e) => {
    const newTableName = e.target.value.toUpperCase();
    const re = /^[A-Za-z]+$/;
    if (newTableName === "" || re.test(newTableName)) {
      setTableName(newTableName);
      setTableNameNotFilled(false);
      setUseAlphabets(false);
    } else {
      setUseAlphabets(true);
    }
  };
  // const handlePhoneNumberChange = (e) => {
  //   const inputValue = e.target.value;
  //   // Check if the input consists only of numbers
  //   if (/[^0-9]/g.test(inputValue) && !inputValue.includes('e') || !inputValue.includes('E')) {
  //     setPhoneNumber(inputValue.replace(/[^0-9]/g, ""));
  //   }
  // };

  const [isValid, setIsValid] = useState(false);
  const handlePhoneNumberInputChange = (value, country, e, formattedValue) => {
    const inputValue = value
    setIsValid(getLengthWithoutSpecialChars(String(country.format)) === getLengthWithoutSpecialChars(inputValue))
    if (/[^0-9]/g.test(inputValue) && !inputValue.includes('e') || !inputValue.includes('E')) {
      setPhoneNumber(inputValue.replace(/[^0-9]/g, ""));
    }
  };

  // trim input of phone number  
  function getLengthWithoutSpecialChars(str) {
    const digitRegex = /\d|\./g;
    const digitsOnly = str.match(digitRegex);
    return digitsOnly ? digitsOnly.length : 0;
  }

  // let permissionRequestController = null; 
  const toggleNotificationStatus = async () => {
    setNotificationEnabled(prevStatus => !prevStatus);
  };

  const verfiTableNotification = () => {
    //console.log(showSwitch);
    FloorPlanRepo.getFloorPlanByNumber(busLocID, table_no).then(
      (response) => {
        // console.log("floor plan status", response?.data.data[0].isNotificationEnabled);
        if (response?.data.data[0].isNotificationEnabled === false
          || response?.data.data[0].table_type === "Standard-Service"
        ) {
          setShowSwitch(false)
        } else {
          setShowSwitch(true)
        }

      }
    )
      .catch((error) => {
        console.error("Error fetching floor plan:", error);
      });

  }
  useEffect(() => {
    if (busLocID && table_no) {
      verfiTableNotification()
    }
  }, [busLocID, table_no])

  const isSafari = useSafariDetection()

  return (
    <>
      <section className="joinScreen w-100 ">
        <h2
          className={` pb-2  joinHdng fs-${page_theme?.font_size_primary_title} font-${page_theme?.font_family_primary_title}`}>
          EINEN NEUEN TISCH ERÖFFNEN<br></br>ODER EINEM TISCH BEITRETEN
        </h2>
        <div className="d-flex justify-content-center p-1 align-items-center">
          <div
            style={{
              height: "120px",
              width: "150px"
            }}>
            <img src="./img/mod3.png" className="w-100 h-100" alt="icon"></img>
          </div>
        </div>
        <h4 className="mb-0 pb-0 pt-3">GEMEINSAM ESSEN?</h4>
        <div className="mt-0 pt-0">Nur einer von Euch eröffnet einen Tisch!</div>
        <div>Alle Anderen treten unter dem gleichen Namen bei.</div>
        <h4 className="pt-3 pb-0 mb-0">UNABHÄNGIG ESSEN?</h4>
        <div className="pt-0 mt-0 pb-3  ">
          Eröffne einen neuen Tisch mit einem Namen deiner Wahl!
        </div>

        <div className="d-flex justify-content-center">
          <div className={"mb-5 userExist " + (isErrorMessage ? "active" : "")}>
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                setIsErrorMessage(false);
              }}></i>
            <h4>Tisch bereits belegt</h4>
            <p>Aktuell wird dieser Name bereits verwendet, bitte formuliere eine Alternative</p>
          </div>
        </div>

        <ModalInterface
          display={showPeople}
          closeButton={true}
          onHide={() => setShowPeople(false)}
          title={
            <>
              <div className="d-flex justify-content-center">
                <div className="d-flex align-self-center mt-2"> Anzahl der Personen</div>
                <div></div>
              </div>
              <div className="dish-cross-modal d-flex align-items-center">
                <i
                  className="fa fa-close fa-stack-1x fa-inverse text-white"
                  onClick={() => {
                    setShowPeople(false);
                    setShowCapacity(false);
                  }}></i>
              </div>
            </>
          }
          content={
            <form
              onSubmit={(e) => {
                if (tableName !== null || tableName !== undefined || tableName?.length > 0) {
                  handleSubmit(e);
                }
              }}
              className="text-center">
              {/* <label>Number of people at Table</label> */}
              <input
                type="number"
                className="vapiano_table border"
                id="numberOfPeople"
                placeholder="Anzahl der Personen am Tisch"
                value={numberOfPeople}
                onChange={(e) => {
                  setNumberOfPeople(e.target.value);
                }}
                required
              />
              <span className={"fill-form2 " + (tableNameNotFilled ? "active" : "")}>
                Fill in the form to proceed!
              </span>
              <span className={"fill-form2 " + (showCapacity ? "active" : "")}>{showCapacity}</span>

              <button
                type="submit"
                className={`btntable  btnCreate fs-${page_theme?.font_size_create_table_btn} font-${page_theme?.font_family_create_table_btn}`}
                style={{
                  color: page_theme?.primary_create_table_btn_text_color,
                  backgroundColor: page_theme?.primary_create_table_btn_bgcolor
                }}
                disabled={
                  submitting ||
                  tableName === null ||
                  tableName === undefined ||
                  tableName?.length <= 0
                }>
                {submitting && actionType === WELCOME_ACTION_TYPE.CREATE_TABLE && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                )}

                {/* <Link to={"/home"} className="text-white"> */}
                {!(submitting && actionType === WELCOME_ACTION_TYPE.CREATE_TABLE) && "LOS GEHT’S"}
                {/* </Link> */}
              </button>
            </form>
          }
        />


        <form
          className="createForm"
        >
          <input
            type="text"
            className="vapiano_table text-uppercase"
            id="vapiano-table-create"
            placeholder="HIER DEINEN TISCH BENENNEN..."
            value={tableName}
            required
            onChange={handleTableNameChange}
          />
          <span className={"fill-form2 " + (tableNameNotFilled ? "active" : "")}>
            Fill in the form to proceed!
          </span>
          <span className={"fill-form2 " + (UseAlphabets ? "active" : "")}>Only use alphabets</span>

          {
            !pickupWithoutNotif && showSwitch && tableType === "Pick-up" &&
            <FormControlLabel
              control={<NotifSwitch sx={{ m: 2 }} checked={notificationEnabled} onChange={toggleNotificationStatus} />}
              label={notificationEnabled ? 'Benachrichtigungen aktiviert' : 'Benachrichtigungen deaktiviert'}
            />
          }

          <div className="text-center btnCreatJoin">
            <button
              type="submit"
              style={{
                backgroundColor: page_theme?.primary_create_table_btn_bgcolor,
                color: page_theme?.primary_create_table_btn_text_color
              }}
              className={`btntable btnCreate me-2 mb-3 mt-2 fs-${page_theme?.font_size_create_table_btn} font-${page_theme?.font_family_create_table_btn}`}
              disabled={submitting}
              onClick={(e) => {
                e.preventDefault();
                // setShowPeople(true);
                handleSubmit(e);
              }}>
              {submitting && actionType === WELCOME_ACTION_TYPE.CREATE_TABLE && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
              )}

              {/* <Link to={"/home"} className="text-white"> */}
              {!(submitting && actionType === WELCOME_ACTION_TYPE.CREATE_TABLE) && "NEUER TISCH "}
              {/* </Link> */}
            </button>

            <button
              type="submit"
              style={{
                backgroundColor: page_theme?.primary_join_table_btn_bgcolor,
                color: page_theme?.primary_join_table_btn_text_color
              }}
              className={` btntable btnCreate  mb-3 mt-2 fs-${page_theme?.font_size_join_table_btn} font-${page_theme?.font_family_join_table_btn}`}
              disabled={
                submitting ||
                tableName === null ||
                tableName === undefined ||
                tableName?.length <= 0
              }
              onClick={(e) => {
                e.preventDefault();
                if (tableName !== null || undefined) {
                  joinTable();
                }
              }}>
              {submitting && actionType === WELCOME_ACTION_TYPE.JOIN_TABLE && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"></span>
              )}
              {/* <Link to={"/home"} className="text-white"> */}
              {!(submitting && actionType === WELCOME_ACTION_TYPE.JOIN_TABLE) && "BEITRETEN"}
              {/* </Link> */}
            </button>
          </div>
        </form>
      </section>
      <ModalInterface
        display={erroralertMsg}
        size="lg"
        center="true"
        handleClose={async () => {
          ShowerroralertMsg(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                {" "}
                <i
                  role="button"
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    ShowerroralertMsg(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h1 className="text-center">{errorMsgResponce}</h1>
          </>
        }
      />
    </>
  );
};
export default TableRegistration;
