import { Button, Container, Form, Row } from "react-bootstrap";
import "../assets/css/service.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { socket } from "../services/socket.services";
import ModalInterface from "../common/ModalInterface";
const Service = () => {
  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.service
  );
  const blId = useSelector((state) => state.table?.business_Info?.blId);
  const [messageDetails, setMsgDetails] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [succesMsg, setSuccessMsg] = useState(false);
  const [emptyTextMsg, setEmptyTextMsg] = useState(false);

  const tableInfo = useSelector((state) => state?.table);

  useEffect(() => {
    setMsgDetails("");
  }, []);

  const emptyAlert = () => {
    setEmptyTextMsg(true);
  };
  const handleProblem = () => {
    setSuccessMsg(true);
    setIsDisabled(true);
    const msg = {
      blId: blId,
      problemID: uuidv4(),
      tableNo: tableInfo?.orderInfo?.tableNumber,
      sessionId: tableInfo?.sessionId,
      message: messageDetails.trim(),
      service: true
    };
    // console.log({ msg });
    socket.emit("service_notification", msg);
    setMsgDetails("");
    // alert("Submission Successful");
    // setMsgDetails(null);
    setTimeout(() => {
      setMsgDetails(null);
      setIsDisabled(false);
    }, 1000);
  };
  return (
    <>
      <Container className=" mt-5 pt-4 mb-5 pb-5">
        <Row className=" mt-3 mb-5 pb-5">
          <div className="d-flex align-items-center justify-content-center mt-2 mainLogo">
            <img src="./img/vapiano_logo.png" alt="logo" className=" img-responsive" width={300} />
          </div>
          <div className=" d-flex align-items-center justify-content-center mt-2">
            <div className="d-flex">
              <div className=" mt-3">
                <h1
                  className={`text-center fs-${page_theme?.font_size_service_title} font-${page_theme?.font_family_service_title}`}
                  style={{
                    fontWeight: "600",
                    color: page_theme?.primary_service_title_color
                  }}
                >
                  SERVICE
                </h1>
                <div className="orderTopWrap">
                  <h6
                    className={`fs-${page_theme?.font_size_help_title_title} font-${page_theme?.font_family_help_title}`}
                    style={{
                      // fontSize: "22px",
                      fontWeight: "600",
                      color: page_theme?.primary_help_title_color
                    }}
                  >
                    BRAUCHST DU HILFE?
                  </h6>
                  <img src="/img/user.png" width={50} height={50} alt="user" />
                  <img
                    className="questionImg"
                    src="/img/Tamatar.png"
                    // src={page_theme?.right_icon_url}
                    alt="question_mark"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`problem-btns-dv ${succesMsg ? "d-none" : ""}`}>
            <div
              // style={{ marginTop: "-20px" }}
              className={`w-100 d-flex flex-column`}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (
                    messageDetails === null ||
                    messageDetails === undefined ||
                    messageDetails.length === 0
                  ) {
                    // alert("Please Schreib uns hier deine Frage");
                    emptyAlert();
                  } else if (
                    messageDetails !== null ||
                    messageDetails !== undefined ||
                    messageDetails.length !== 0
                  ) {
                    handleProblem();
                  }
                }}
              >
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  {/* <Form.Label>Schreib uns hier deine Frage</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    placeholder={"SCHREIB UNS HIER DEINE FRAGE...."}
                    rows={7}
                    // required
                    value={messageDetails}
                    style={{
                      border: "1px solid",
                      color: page_theme?.primary_description_text_color
                    }}
                    className={`fs-${page_theme?.font_size_description_title} font-${page_theme?.font_family_description_title}`}
                    onChange={(e) => setMsgDetails(e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button
                    // variant="success"
                    type="submit"
                    className={`align-self-end fs-${page_theme?.font_size_confirm_btn} font-${page_theme?.font_family_confirm_btn}`}
                    disabled={isDisabled}
                    style={{
                      backgroundColor: page_theme?.primary_confirm_btn_bgcolor,
                      color: page_theme?.primary_confirm_btn_text_color,
                      border: " none",
                      borderRadius: "20px",
                      width: "150px"
                    }}
                  // onClick={}
                  >
                    Bestätigen
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div className={`submissinoSucces p-5  ${!succesMsg ? "d-none" : " "}`}>
            <h1 className="text-danger text-center fw-bold">NACHRICHT ZUGESTELLT!</h1>
            <p>
              <h5 className="text-center ps-3 pe-3 pt-5">
                VIELEN DANK WIR HABEN DEINE NACHRICHT ERHALTEN UND KÜMMERN UNS UM DEINE ANLIEGEN
              </h5>
            </p>
          </div>
        </Row>
        <ModalInterface
          display={emptyTextMsg}
          size="lg"
          center="true"
          handleClose={async () => {
            setEmptyTextMsg(false);
          }}
          title={
            <>
              <div className="d-flex justify-content-end p-2">
                <span
                  className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                  style={{ height: "25px", width: "25px" }}
                >
                  {" "}
                  <i
                    className="fa fa-close fs-4"
                    onClick={async () => {
                      setEmptyTextMsg(false);
                    }}
                  ></i>
                </span>
              </div>
            </>
          }
          content={
            <>
              <h1>Please Schreib uns hier deine Frage</h1>
            </>
          }
        />
      </Container>
    </>
  );
};

export default Service;
