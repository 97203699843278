import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCartLength } from "../../store/actions/orderAction";
import BillNoItem from "../modals/BillnoItem";
import { checkIsGuestBook } from "../../store/actions/guestBookAction";

const NavigationDashboard = () => {
  const orders = useSelector((state) => state.order);
  const [isTotalOrderTimeOut, setIsTotalOrderTimeOut] = useState(false);
  const dispatch = useDispatch();
  const [billModal, setBillModal] = useState(false);
  const orderCount = orders?.ordersPlaced.length;
  const isGuestBook = useSelector((state) => state.table.isGuestBook);

  useEffect(() => {
    dispatch(checkIsGuestBook());
  }, []);

  useEffect(() => {
    dispatch(GetCartLength());
    if (orders.Carts.length) {
      setIsTotalOrderTimeOut(false);
      checkTime(new Date(orders?.Carts[0]?.endTime));
    }
  }, [orders.Carts.length]);
  const checkTime = (end) => {
    let interval = setInterval(() => {
      var now = new Date();
      var distance = end - now;
      if (distance < 0) {
        setIsTotalOrderTimeOut(true);
        clearInterval(interval);
        return;
      }
    }, 1000);
  };
  return (
    <>
      <Navbar
        fixed="bottom"
        expand="lg"
        style={{
          backgroundColor: "#2f3e3f",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "20px",
          width: "100%"
        }}
        className="justify-content-between"
      >
        <NavLink
          to="/home"
          className={({ isActive }) =>
            isActive
              ? "text-decoration-none text-center align-items-center d-flex flex-column active-navlink"
              : "text-decoration-none text-white text-center d-flex flex-column align-items-center inactive-navlink"
          }
        >
          {({ isActive }) => {
            return (
              <>
                <img
                  src={isActive ? "img/carousel/icon_menu_r.png" : "img/carousel/icon_menu_w.png"}
                  height={27}
                  width={27}
                />
                <span>SPEISEKARTE</span>
              </>
            );
          }}
        </NavLink>

        <NavLink
          to={orderCount > 0 && "/bill"}
          className={({ isActive }) =>
            orderCount > 0 && isActive
              ? " d-flex flex-column text-decoration-none text-center align-items-center active-navlink-bill"
              : " d-flex flex-column text-decoration-none text-white text-center align-items-center inactive-navlink"
          }
          onClick={() => {
            if (orderCount === 0) setBillModal(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="30px"
            className="d-block"
            // width="201.000000pt"
            // height="200.000000pt"
            viewBox="0 0 201.000000 200.000000"
            preserveAspectRatio="xMidYMid meet"
            // className="svg"
          >
            <g
              transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
              fill="#fff"
              //className="g"
              stroke="none"
            >
              <path d="M365 1746 c-14 -20 -15 -112 -13 -785 3 -742 4 -763 22 -777 11 -8 28 -14 37 -14 10 0 66 36 125 80 l108 80 82 -80 c45 -44 89 -80 99 -80 9 0 52 34 94 75 43 41 81 75 86 75 5 0 43 -34 86 -75 42 -41 85 -75 94 -75 10 0 55 36 100 81 l83 81 108 -82 c89 -66 113 -80 134 -75 14 3 31 13 38 22 9 13 12 183 12 773 0 668 -2 758 -16 778 l-15 22 -624 0 -625 0 -15 -24z m1175 -742 l0 -656 -37 28 c-21 15 -58 43 -82 61 -24 18 -50 33 -59 33 -8 0 -49 -34 -91 -75 -43 -41 -81 -75 -86 -75 -5 0 -43 34 -86 75 -42 41 -84 75 -94 75 -10 0 -53 -35 -96 -77 l-80 -77 -72 71 c-90 89 -108 95 -167 50 -25 -18 -62 -46 -82 -61 l-38 -28 0 656 0 656 535 0 535 0 0 -656z" />
              <path d="M670 1400 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 333 -20 346 0 357 2 357 60 0 58 -11 60 -357 60 -300 0 -314 -1 -333 -20z" />
              <path d="M667 1102 c-21 -23 -22 -52 -3 -78 14 -18 29 -19 341 -19 312 0 327 1 341 19 19 27 18 60 -4 79 -17 15 -55 17 -339 17 -297 0 -321 -1 -336 -18z" />
              <path d="M667 802 c-22 -24 -21 -55 1 -75 17 -15 55 -17 339 -17 297 0 321 1 336 18 21 23 22 56 1 76 -13 14 -58 16 -338 16 -300 0 -324 -1 -339 -18z" />
            </g>
          </svg>

          {/* <i
            className="fa fa-file-invoice d-block fa-xl mb-2"
          ></i> */}
          <span className="text-center">BESTELLUNG </span>
        </NavLink>

        <NavLink
          to="/order"
          style={{ position: "relative" }}
          className={({ isActive }) =>
            isActive
              ? "flex-column  d-flex text-decoration-none text-center text-info align-items-center active-navlink"
              : " d-flex flex-column text-decoration-none text-white text-center align-items-center inactive-navlink"
          }
        >
          {orders?.Carts.length > 0 && (
            <span
              className="countTotalOrder totalOrder"
              style={
                isTotalOrderTimeOut
                  ? {
                      display: "block",
                      animation: "totalOrder 1s infinite"
                    }
                  : { display: "block" }
              }
            >
              {orders?.Carts.length}
            </span>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="30px"
            className="d-block  "
            // width="201.000000pt"
            // height="200.000000pt"
            viewBox="0 0 201.000000 200.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
              fill="#fff"
              stroke="none"
            >
              <path d="M955 1680 c-49 -20 -91 -97 -79 -145 5 -19 -2 -23 -66 -38 -297 -71 -543 -316 -629 -626 -11 -40 -23 -99 -27 -132 l-7 -59 -39 0 c-54 0 -76 -14 -84 -55 -18 -96 47 -251 121 -289 39 -21 53 -21 835 -24 555 -2 811 0 846 8 100 21 164 124 164 260 0 83 -13 100 -74 100 l-42 0 -12 76 c-16 104 -65 244 -115 333 -53 94 -178 227 -272 289 -81 55 -192 104 -277 123 l-58 12 0 41 c0 97 -97 163 -185 126z m79 -117 c4 -9 0 -23 -9 -32 -13 -14 -17 -14 -31 1 -9 9 -14 24 -11 32 8 21 43 20 51 -1z m151 -173 c213 -54 395 -203 494 -403 39 -79 81 -223 81 -279 l0 -28 -750 0 -750 0 0 33 c0 51 37 184 70 256 76 162 219 306 374 377 142 65 331 82 481 44z m699 -847 c-11 -47 -25 -77 -41 -95 -15 -17 -67 -18 -833 -18 -795 0 -817 1 -834 19 -18 19 -45 84 -46 109 0 9 182 12 880 12 l880 0 -6 -27z" />
              <path d="M662 1258 c-96 -55 -239 -207 -266 -282 -10 -29 19 -66 53 -66 25 0 36 10 75 69 52 78 101 126 184 178 62 40 72 49 72 76 0 20 -34 57 -52 57 -7 0 -37 -14 -66 -32z" />
            </g>
          </svg>

          {/* </> */}

          {/* <i
            className="fa fa-shopping-cart fa-xl mb-2 d-block"
            aria-hidden="true"
          >
            {orders?.numberOfOrdersInCart != 0 && (
              <span className="text-primary p-2 d-inline bg-light rounded-circle">
                {orders?.numberOfOrdersInCart != 0 &&
                  orders?.numberOfOrdersInCart}
              </span>
            )}
          </i> */}

          <span>WARENKORB</span>
        </NavLink>
        {isGuestBook && (
          <NavLink
            to="/guestBook"
            className={({ isActive }) =>
              isActive
                ? "text-decoration-none text-center align-items-center active-navlink-guest"
                : "text-decoration-none text-white text-center align-items-center inactive-navlink"
            }
          >
            {/* <i className="fa-solid fa-book d-block fa-xl mb-2"></i> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="30px"
              className="d-block m-auto"
              // width="201.000000pt"
              // height="200.000000pt"
              viewBox="0 0 201.000000 200.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path d="M226 1748 c-14 -20 -16 -99 -16 -728 0 -622 2 -708 16 -728 15 -22 16 -22 315 -22 l299 0 39 -39 c39 -39 91 -61 141 -61 39 1 103 34 132 69 l27 31 155 0 156 0 0 -33 c0 -33 24 -67 48 -67 22 0 52 40 52 70 l0 30 94 0 c73 0 98 4 110 16 14 14 16 94 16 734 0 640 -2 720 -16 734 -13 14 -55 16 -308 16 -323 0 -358 -5 -435 -61 -43 -31 -43 -31 -65 -11 -11 11 -41 30 -66 43 -44 24 -51 24 -362 27 l-316 3 -16 -23z m649 -92 c19 -8 46 -26 60 -40 l25 -25 0 -527 0 -526 -22 8 c-13 4 -159 9 -325 11 l-303 4 0 555 0 554 265 0 c209 0 273 -3 300 -14z m835 -540 l0 -555 -302 -3 c-224 -2 -309 -6 -325 -16 l-23 -13 0 529 0 528 28 27 c55 53 75 56 360 57 l262 0 0 -554z m-785 -676 c22 -11 43 -27 48 -35 12 -22 61 -18 83 6 37 42 73 49 259 49 l175 0 0 -45 0 -45 -180 0 c-164 0 -181 -2 -196 -19 -59 -70 -74 -81 -104 -81 -31 0 -56 19 -93 74 l-18 26 -294 0 -295 0 0 45 0 45 288 0 c268 0 290 -1 327 -20z m785 -25 l0 -45 -60 0 -60 0 0 45 0 45 60 0 60 0 0 -45z" />
                <path d="M418 1419 c-21 -12 -24 -59 -6 -77 17 -17 429 -17 446 0 19 19 15 66 -7 78 -26 13 -410 13 -433 -1z" />
                <path d="M418 859 c-21 -12 -24 -59 -6 -77 17 -17 429 -17 446 0 19 19 15 66 -7 78 -26 13 -410 13 -433 -1z" />
                <path d="M1168 1419 c-21 -12 -24 -59 -6 -77 9 -9 73 -12 224 -12 205 0 213 1 228 21 16 23 12 47 -12 67 -20 15 -408 17 -434 1z" />
                <path d="M1162 1228 c-19 -19 -15 -66 7 -78 30 -15 413 -13 433 3 24 19 28 43 12 66 -15 20 -23 21 -228 21 -151 0 -215 -3 -224 -12z" />
                <path d="M1162 1038 c-16 -16 -15 -53 2 -67 9 -8 78 -11 226 -9 202 3 213 4 226 23 12 18 11 24 -2 43 -15 22 -20 22 -228 22 -151 0 -215 -3 -224 -12z" />
                <path d="M1168 859 c-21 -12 -24 -59 -6 -77 9 -9 73 -12 224 -12 205 0 213 1 228 21 16 23 12 47 -12 67 -20 15 -408 17 -434 1z" />
              </g>
            </svg>
            <span>GÄSTEBUCH</span>
          </NavLink>
        )}

        <NavLink
          to="/service"
          className={({ isActive }) =>
            isActive
              ? "text-decoration-none text-center align-items-center active-navlink-service"
              : "text-decoration-none text-white text-center align-items-center inactive-navlink"
          }
        >
          {/* <i className="fas fa-hands-helping d-block fa-xl mb-2"></i> */}
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            className="d-block m-auto"
            // width="201.000000pt"
            // height="200.000000pt"
            viewBox="0 0 201.000000 200.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
              fill="#ffffff"
              stroke="none"
            >
              <path d="M891 1744 c-233 -63 -392 -300 -351 -525 15 -80 42 -151 74 -193 l25 -33 -55 -38 c-180 -124 -307 -352 -316 -566 -4 -79 15 -109 67 -109 47 0 61 24 74 128 6 51 21 117 32 146 44 119 143 238 252 304 l54 33 52 -26 c134 -68 290 -66 432 6 l36 18 63 -37 c158 -93 279 -305 280 -489 0 -75 72 -111 121 -62 20 20 21 29 15 96 -19 227 -136 434 -313 554 -26 18 -49 33 -51 34 -2 2 13 31 32 66 61 111 79 218 55 331 -55 262 -324 431 -578 362z m251 -151 c65 -30 133 -95 165 -162 24 -49 28 -68 28 -146 0 -84 -2 -95 -34 -153 -81 -149 -249 -213 -411 -158 -77 27 -169 119 -196 196 -79 231 87 458 326 447 48 -2 92 -11 122 -24z"></path>
            </g>
          </svg>
          <span>SERVICE</span>
        </NavLink>
      </Navbar>
      <BillNoItem billModal={billModal} setBillModal={setBillModal} />
    </>
  );
};

export default NavigationDashboard;
