import React from "react";

const OrderPlacedModal = () => {
  return (
    <>
      <section className="credit">
        <div className="divideCalc3">
          <h1>
            Vielen Dank!
            <br /> Bitte merke dir deine Tischnummer
            <br /> Und bezahle an der Kasse am Ausgang
          </h1>
          {/* <span>your table</span> */}
        </div>
      </section>
      {/* <button
        className="okBtn"
        onClick={() => {
          props.setmodalProceed(true);
        }}
      >
        Proceed
      </button> */}
    </>
  );
};

export default OrderPlacedModal;
