import React, { useEffect } from "react";
import { Canvas } from "../components/guestBook/Canvas";
import { Container, Stack } from "react-bootstrap";
import { WallOfFame } from "../components/guestBook/WallOfFame";
import { useSelector, useDispatch } from "react-redux";
import { checkIsGuestBook } from "../store/actions/guestBookAction";
import { useNavigate } from "react-router-dom";

const GuestBook = () => {
  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.guest_wall
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const isGuestBook = useSelector((state) => state.table.isGuestBook);

  useEffect(() => {
    dispatch(checkIsGuestBook());
    // .then((resp) => {
    //   if (resp?.success) {
    //     setIsGuestBookAllowed(resp?.data?.guest_book);
    //     if (!resp?.data?.guest_book) {
    //       navigate("/home");
    //     }
    //   }
    // });
  }, []);
  useEffect(() => {
    if (!isGuestBook) {
      navigate("/home");
    }
  }, [isGuestBook]);

  return (
    <>
      {!isGuestBook ? (
        <div className=" min-vh-100 w-100">
          <span
            className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <div className=" " style={{ marginTop: "-8px" }}>
          <div
            className="mainLogo text-center pt-3 pb-3  w-100 mt-0"
            style={{
              position: "fixed",
              background: "rgb(247, 243, 238)",
              zIndex: "100"
            }}
          >
            <img src={"/img/vapiano_logo.png"} alt="main_logo" width={"280px"} />
          </div>

          <div style={{ width: "97%", marginTop: "61px" }}>
            <Stack>
              <div className="d-flex justify-content-between" style={{ marginTop: "100px" }}>
                <div
                  className={`ps-2 ps-lg-5 ps-lg-md-5  pt-2  font-${page_theme?.font_family_guestbook_entry_title}`}
                  // fs-${page_theme?.font_size_guestbook_entry_title}
                  style={{
                    color: page_theme?.primary_guestbook_entry_text_color,
                    fontWeight: "600",
                    fontSize: "18px"
                  }}
                >
                  {" "}
                  Dein GÄSTEBUCHEINTRAG
                </div>
                <div>
                  <img
                    src={page_theme?.right_icon_url}
                    className="img-fluid ms-auto"
                    alt="Icon"
                    width={70}
                    height={30}
                  />
                </div>
              </div>
            </Stack>
          </div>
          <Container className="boardDrawing">
            <Canvas page_theme={page_theme} />

            {/* <hr /> */}
          </Container>
          <div className="p-4">
            <WallOfFame page_theme={page_theme} />
          </div>
        </div>
      )}
    </>
  );
};

export default GuestBook;
