/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Stack, } from "react-bootstrap";
// import { Carousel, CarouselItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModalInterface from "../../common/ModalInterface";
import { Cart } from "./Cart";
import {
  getSingleIngredients,
  loadDescription,
  SetCartData as SetCartDataAction
} from "../../store/actions/menuAction";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { SearchResults } from "./SearchResults";
import {
  getCourses,
  // loadOrdersBySession,
  Sync_Carts
} from "../../store/actions/orderAction";
import "../../assets/css/arrow.css";

// import BestSeller from "../../repository/bestSeller";
import BestSeller from "./bestSeller";
import VeganVegetarian from "./VeganVegetarian";
import OrderDetails from "../order/orderdetails";
// import ScrollContainer from "react-indiana-drag-scroll";
// import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import VapianoNavBar from "../navbar/vapianonavbar";
import { handleSetActive } from "../../helper/scrollActive";
import SpecialSection from "./SpecialSection";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow } from 'swiper/modules';
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { compareDesc, parse } from "date-fns";
function compareDate(firstDate, secondDate) {
  if (compareDesc(new Date(firstDate), new Date(secondDate)) === -1) return false
  else return true
}

async function getAllGroups(group) {
  try {
    // const allgroups = await axios.get(absoluteUrl(`/group-logo/${blId}`));
    return group.filter(function filterVisibleGroups(group) {
      const startTime = parse(group.startTime, "HH:mm", new Date());
      const endTime = parse(group.endTime, "HH:mm", new Date());
      if (typeof group.visible === 'undefined' || group.visible === null) {
        return true;
      }
      if (!group.active && group.visible) return true;
      if (group.active && group.visible && compareDate(new Date(), endTime) && compareDate(startTime, new Date()))
        return true;
      return false;
    });
    // return allgroups.data.data;
  } catch (error) {
    console.error("ERROR_FETCHING_GROUPS", error);
  }
}

export const Menus = ({
  data,
  group,
  loadingGroup,
  loading,
  loadingSpecials,
  veganItems,
  bestSellerData,
  specialsItem,
  searchSectionRef
}) => {
  const [, setOtherCategoryLoading] = useState(true);

  const dispatch = useDispatch();
  const categoryIs = useSelector((state) => state.table.business_Info);
  const orders = useSelector((state) => state.order);
  const cartData = useSelector((state) => state.menu.cartData);
  const [closemodalBtn, setClosemodalBtn] = useState(false);
  const newsSection = useSelector((state) => state?.promotions.promotion);
  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.menu);
  let [_scrollWidth, setScrollWidth] = useState(0);
  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);
  const binfo = useSelector((state) => state.table?.business_Info);
  const [firstNumber, setFirstNumber] = useState(-1);
  const [secondRandom, setSecondRandom] = useState(-1);
  // const busInfo = useSelector((state) => state.table.orderInfo);
  // const sessionId = useSelector((state) => state.table.sessionId);

  const { data: allGroups } = useQuery({
    queryKey: ['allgroups', group],
    queryFn: () => getAllGroups(group)
  });

  let goToItemsOBJ = {
    Pasta: { name: "PASTA", img: "pasta", category: "Pasta" },
    Pinsa: { name: "PINSA", img: "pinsa", category: "Pinsa" },
    Insalata: { name: "SALADS", img: "salad", category: "Insalata" },
    Risotto: { name: "RISOTTI", img: "risotto", category: "Risotto" },
    "Wasser & Softdrinks": {
      name: "DRINKS",
      img: "drinks",
      category: "Wasser & Softdrinks"
    },
    Dolci: { name: "DOLCI", img: "dessert", category: "Dolci" },
    "Caffé & Té": { name: "COFFE", img: "coffee", category: "Caffé & Té" }
  };

  useEffect(() => {
    if (sessionStorage.getItem("intro") == null && popUp === true) {
      setShowIntroPopUp(true);
      setOtherCategoryLoading(false);

      sessionStorage.setItem("intro", "popupsetup");

      setTimeout(() => {
        if (sessionStorage.getItem("intro") !== null) {
          setShowIntroPopUp(false);
        }
      }, 10000);
    }
    setFirstNumber(Math.floor(Math.random() * (data.length - 0 + 1)) + 0);
    setSecondRandom(Math.floor(Math.random() * (data.length - 0 + 1)) + 0);
    console.log("__group",data);
    //to cater for existing orders to be fetched when user joins an existing session
    // if (sessionStorage.getItem("session_type") === "join")
    //   dispatch(
    //     loadOrdersBySession({
    //       businessLocationId: busInfo?.businessLocationId,
    //       sessionId: sessionId
    //     })
    //   );
  }, []);

  const suggestion_font = { font_size_menu_item_price: "20px" };
  // useEffect for resizing
  useEffect(() => {
    if (orders.courses.length <= 0) {
      dispatch(getCourses());
    }
  }, [_scrollWidth]);

  const resetSwipe = () => {
    setTimeout(() => {
      setSwiping(false);
    }, 100);
  };
  const [swiping, setSwiping] = useState(false);
  /// Responsive Carousel
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    hideScrollbars: true,
    // centerMode: true,
    // centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 3,
          centerMode: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 3,
          initialSlide: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 3,
          initialSlide: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
          centerMode: false
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
          centerMode: false
        }
      }
    ],
    onSwipe: function() {
      setSwiping(true);
      resetSwipe();
    }
  };
  let navigate = useNavigate();
  const [showCart, setShowCart] = useState(false);
  const [showIntroPopUp, setShowIntroPopUp] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [infoAllergens, setInfoAllergens] = useState(false);
  const [newsBanner] = useState(newsSection);
  const [allergenData, setAllergenData] = useState([
    // { title: "sdff", icon: "dfsd", id: "123", _id: "sdf" },
  ]);

  // let groups = Array.from(set);
  let popUp = newsBanner?.map((prom) => {
    return prom?.popup?.map((url) => {
      return url;
    });
  });
  popUp = popUp?.flat();
  popUp = popUp[0]?.popup;

  // defining items
  let set = new Set();
  let customItem = ["Special", "Best Seller", "VEGAN"];
  customItem?.map((item) => set?.add(item));
  data?.map((item) => set?.add(item?.group));
  let groups = Array?.from(set);
  customItem?.push(groups);
  useEffect(() => {
    let navbarMenu = document.getElementById("navbar-menu");
    if (navbarMenu) setScrollWidth(navbarMenu.scrollWidth);
  }, [groups]);

  const checkGroup = (group) => {
    const thisGroup = allGroups ? allGroups.find((thisGroup) => thisGroup.group === group) : null;
    if (thisGroup) {
      const groupItems = data?.filter((item) => item?.group === group);
      // groupItems.find((thisGroup)=> data)
      groupItems?.sort((a, b) => {
        if (!b.priority) {
          return -1;
        }
        return a.priority - b.priority;
      });
      return groupItems;
    }
    return [];
  };


  const checkGroupImg = (group) => {
    if (binfo.groupImages) {
      const groupItems = data?.filter((item) => item?.group === group);
      const firstItemImageInTheGroup = groupItems[0]?.image

      const groupLogo = allGroups?.filter((item) => item?.group === group && item?.image);
      const groupLogoImage = groupLogo[0]?.image

      const groupImg = JSON.stringify(groupLogoImage || firstItemImageInTheGroup);

      return groupImg;
    }
    return null;
  };

  const [testImgIndex, setTestImgIndex] = useState(-1);
  const testSpecials = checkGroup(groups[3]);
  testSpecials.splice(3, 1);
  const testImg = [
    "./img/PlatenKnife.png",
    "https://appapi1.va-piano.ch/5.jpg",
    "https://appapi1.va-piano.ch/1.jpg"
  ];

  return (
    <>
      <ModalInterface
        display={showIntroPopUp}
        center="true"
        title={""}
        handleClose={() => {
          setShowIntroPopUp(false);
        }}
        setDisplay={setShowIntroPopUp}
        content={
          <>
            <div className="introModalImg text-center">
              {/* <img
                      className="img-fluid"
                      alt="offer popup ...Skip"
                      src={"img/intro_popup_1649648502621_pop_up1.png"}
                    /> */}
              {newsBanner?.map((prom) => {
                return prom?.popup?.map((url) => {
                  return (
                    <a href={url?.url} target="_blank" rel="noreferrer" key={uuidv4()}>
                      <img
                        style={{ width: "80%" }}
                        className="img-fluid"
                        src={url?.popupImage}
                        alt="Promotions"
                      />
                    </a>
                  );
                });
              })}
            </div>
            <div className="d-flex justify-content-center mt-3">
              <img
                src="/img/cancel.png"
                onClick={() => setShowIntroPopUp(false)}
                className="banner-image"
                alt="icon"
              />
              {/* </button> */}
            </div>
          </>
        }
        closeEvent={() => {
          setShowIntroPopUp(false);
        }}
      />

      {/* {loading ? ( */}
      {loadingSpecials && categoryIs.special === true && loadingGroup ? (
        <h1 className="p-4 text-center bg-light text-dark " style={{ fontFamily: "amithem" }}>
          Loading SPEISEKARTE...
        </h1>
      ) : (
        <>
          <VapianoNavBar
            loading={loading}
            group={group}
            groups={groups}
            categoryIs={categoryIs}
            _scrollWidth={_scrollWidth}
          />
          {/* NAV Fin */}
          {/* ======================== */}

          {/* Seacrh Section Start */}
          <SearchResults
            groups={groups}
            searchSectionRef={searchSectionRef}
            settings={settings}
            setShowCart={setShowCart}
            setAllergenData={setAllergenData}
            setInfoAllergens={setInfoAllergens}
          />
          {/* Seacrh Section Fin */}

          {/* special Fin */}
          {/* ======================== */}

          <Row className="w-100 gx-0 gy-0">
            <Col md="12" lg="12" sm="12" xs="12">
              {/* Frequent Used Modal  Start*/}
              <ModalInterface
                display={showSuggestions}
                title={
                  <div>
                    <div
                      className="dish-cross-modal d-flex align-items-center"
                      onClick={() => {
                        setShowSuggestions(false);
                      }}>
                      <i
                        className="fa fa-close fa-stack-1x fa-inverse text-white"
                        onClick={() => {
                          setShowSuggestions(false);
                        }}></i>
                    </div>
                  </div>
                }
                handleClose={() => {
                  setShowSuggestions(false);
                }}
                setDisplay={setShowIntroPopUp}
                content={
                  <div className="text-center">
                    <div className="py-2">
                      <h1 className="thankuOrder bigThank">BESTELLVORSCHLÄGE</h1>
                      <h1 className="canSuggest">WIRD HAUFIG ZUSAMMEN BESTELLT</h1>
                      <Row className="mt-5">
                        {cartData?.related_products?.length > 1 ? (
                          cartData?.related_products?.map((product) => {
                            let item = data?.filter((item) => item.sku === product.sku);
                            return (
                              <Col md="6" sm="6" lg="6" xs="6" key={uuidv4()}>
                                <p className="order-modal-title">{product?.productName}</p>

                                <div role="button" className="relatedmodal-img">
                                  {item?.length === 0 ? (
                                    <div className="relatedmodal-img-overlay">
                                      <div className="context-out-of-stock">
                                        <img src="./img/out-of-stock.png" alt="icon" />
                                        <p>This item not availabe for now!</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <img
                                    src={product?.image}
                                    alt="Order Now"
                                    className="img-thumbnail orderModalImg"
                                    height={20}
                                    onClick={() => {
                                      // return;
                                      if (item.length > 0) {
                                        dispatch(SetCartDataAction(item[0]));
                                        dispatch(Sync_Carts(item[0]));
                                        dispatch(
                                          getSingleIngredients({
                                            sku: product?.sku,
                                            blId: busId
                                          })
                                        );
                                        setShowSuggestions(false);
                                        dispatch(
                                          loadDescription({
                                            sku: product.sku,
                                            blId: busId
                                          })
                                        );
                                        dispatch(
                                          getSingleIngredients({
                                            sku: product.sku,
                                            blId: busId
                                          })
                                        );
                                        setShowCart(true);
                                      }
                                    }}
                                  />
                                </div>

                                <OrderDetails
                                  page_theme={suggestion_font}
                                  data={product}
                                  setInfoAllergens={setInfoAllergens}
                                  setAllergenData={setAllergenData}
                                />
                              </Col>
                            );
                          })
                        ) : (
                          <>
                            <Col md="6" sm="6" lg="6" xs="6">
                              <h5 className="order-modal-title">{data[firstNumber]?.name}</h5>
                              <img
                                role="button"
                                src={data[firstNumber]?.image}
                                alt="Pasta Aglio e Olio"
                                className="img-thumbnail orderModalImg"
                                onClick={() => {
                                  dispatch(SetCartDataAction(data[firstNumber]));
                                  dispatch(Sync_Carts(data[firstNumber]));
                                  dispatch(
                                    getSingleIngredients({
                                      sku: data[firstNumber]?.sku,
                                      blId: busId
                                    })
                                  );
                                  setShowSuggestions(false);
                                  dispatch(
                                    loadDescription({
                                      sku: data[firstNumber].sku,
                                      blId: busId
                                    })
                                  );
                                  dispatch(
                                    getSingleIngredients({
                                      sku: data[firstNumber].sku,
                                      blId: busId
                                    })
                                  );
                                  setShowCart(true);
                                }}
                              />
                              <Stack direction="horizontal" className="justify-content-start">
                                <img
                                  role="button"
                                  src="/img/info.png"
                                  alt="info"
                                  height={20}
                                  className={
                                    data[firstNumber]?.allergens?.length > 0
                                      ? "cate-icons visible"
                                      : "cate-icons invisible"
                                  }
                                  onClick={() => {
                                    setAllergenData(data[firstNumber]?.allergens);
                                    setInfoAllergens(true);
                                  }}
                                />
                                <span className="ms-5 item_price">
                                  € {data[firstNumber]?.price}
                                </span>
                              </Stack>
                            </Col>
                            <Col md="6" sm="6" lg="6" xs="6">
                              <h5 className="order-modal-title">{data[secondRandom]?.name}</h5>
                              <img
                                role="button"
                                src={data[secondRandom]?.image}
                                alt="Pasta Aglio e Olio"
                                className="img-thumbnail orderModalImg"
                                onClick={() => {
                                  dispatch(SetCartDataAction(data[secondRandom]));
                                  dispatch(Sync_Carts(data[secondRandom]));
                                  dispatch(
                                    getSingleIngredients({
                                      sku: data[secondRandom]?.sku,
                                      blId: busId
                                    })
                                  );
                                  setShowSuggestions(false);
                                  dispatch(
                                    loadDescription({
                                      sku: data[secondRandom].sku,
                                      blId: busId
                                    })
                                  );
                                  dispatch(
                                    getSingleIngredients({
                                      sku: data[secondRandom].sku,
                                      blId: busId
                                    })
                                  );
                                  setShowCart(true);
                                }}
                              />
                              <Stack direction="horizontal" className="justify-content-start">
                                <img
                                  role="button"
                                  className={
                                    data[secondRandom]?.allergens?.length > 0
                                      ? " cate-icons visible"
                                      : " cate-icons invisible"
                                  }
                                  src="/img/info.png"
                                  alt="info"
                                  height={20}
                                  onClick={() => {
                                    setInfoAllergens(true);
                                    setAllergenData(data[secondRandom]?.allergens);
                                  }}
                                />

                                <span className="ms-5 item_price">
                                  € {data[secondRandom]?.price}
                                </span>
                                {}
                              </Stack>
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>

                    <div className="d-flex flex-wrap justify-content-center gap-3">
                      {groups?.map((category, i) => {
                        if (!goToItemsOBJ[category]) {
                          return;
                        } else {
                          return (
                            <div key={uuidv4()}>
                              <ScrollLink
                                className="px-3 nav-link nav-link-menu"
                                to={`${category}`}
                                activeClass="active-scroll-spy"
                                spy={true}
                                offset={-270}
                                id={`category${i}`}
                                key={uuidv4()}
                                data-id={i}
                                onSetActive={(e) => handleSetActive(e, _scrollWidth)}
                                onClick={() => {
                                  setShowSuggestions(false);
                                }}>
                                <div className=" text-dark text-decoration-none ">
                                  <img
                                    src={`./img/goto/${goToItemsOBJ[category]?.img}.png`}
                                    alt="suggestion"
                                    className="img-responsive"
                                    width={45}
                                    height={35}
                                  />
                                  <figcaption className="bold-600 font14 text-uppercase">
                                    {goToItemsOBJ[category]?.name}
                                  </figcaption>
                                </div>
                              </ScrollLink>
                            </div>
                          );
                        }
                      })}
                    </div>
                    {/* </Stack> */}

                    <div className="suggestBtn">
                      <button
                        className="sugGo text-uppercase"
                        data-bs-dismiss="modal"
                        onClick={() => setShowSuggestions(false)}>
                        ZUR Speisekarte
                        {/* <i className="fa-solid fa-plus"></i> */}
                      </button>
                      <button
                        className="sugOrder choiceGoOrder text-uppercase"
                        onClick={() => {
                          navigate("/order");
                        }}>
                        ZUM Warenkorb
                        {/* <i className="fa-solid fa-chevron-right"></i> */}
                      </button>
                    </div>
                    {/* <Row className="mt-3">
                    <Col md="6" sm="6" lg="6" xs="6">
                      <Link to="/order">
                        <Button
                          size="lg"
                          className="text-white shadow"
                          variant="info"
                          style={{ width: "150px" }}
                        >
                          <span className="ml-3"> Order</span>
                          <li className="fa-solid fa-angle-right fs-6 ps-1 "></li>
                        </Button>
                      </Link>
                    </Col>
                    <Col md="6" sm="6" lg="6" xs="6">
                      <Button
                        size="lg"
                        variant="danger"
                        className="text-white shadow"
                        onClick={() => setShowSuggestions(false)}
                        style={{ width: "150px" }}
                      >
                        <span className="ml-3"> Continue</span>
                        <li className="fa fa-plus fs-6"></li>
                      </Button>
                    </Col>
                  </Row> */}
                  </div>
                }
                closeEvent={() => {
                  setShowSuggestions(false);
                }}
              />
              {/* Frequent Used Modal Fin*/}
              {cartData !== null && showCart && (
                <Cart
                  page_theme={page_theme}
                  showCart={showCart}
                  // ingY={ingY}
                  setShowCart={setShowCart}
                  fakeImg={testImg[testImgIndex]}
                  cartData={cartData}
                  setShowSuggestions={setShowSuggestions}
                  setInfoAllergens={setInfoAllergens}
                  setAllergenData={setAllergenData}
                />
              )}

              <ModalInterface
                display={infoAllergens}
                center={true}
                title={
                  <>
                    <div
                      onMouseEnter={() => {
                        setClosemodalBtn(true);
                      }}
                      onMouseLeave={() => {
                        setClosemodalBtn(false);
                      }}
                      className="d-flex justify-content-end">
                      {closemodalBtn === false ? (
                        <span
                          role="button"
                          className="fa-stack fa-lg fs-6 ms-2 mt-1 justify-content-end"
                          onClick={() => {
                            setInfoAllergens(false);
                          }}>
                          <i className="fa fa-circle fa-stack-2x text-danger"></i>
                          <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
                        </span>
                      ) : (
                        <span
                          role="button"
                          className="fa-stack fa-lg fs-6 ms-2 mt-1 justify-content-start"
                          onClick={() => {
                            setInfoAllergens(false);
                          }}>
                          <i className="fa fa-circle fa-stack-2x text-primary"></i>
                          <i className="fa fa-close fa-stack-1x fa-inverse text-white"></i>
                        </span>
                      )}
                    </div>
                    <div className="text-center text-danger">ALLERGENE</div>
                    <div
                      className="px-1"
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                        marginTop: "10px"
                      }}>
                      Kreuzkontaminationen aller unten angegebenen Allergene, sowie technologisch
                      unvermeidbare Verunreinigungen einzelner Produkte sind nicht auszuschließen.
                      Änderungen vorbehalten, Rezepturänderungen ergänzen wir regelmäßig
                    </div>
                  </>
                }
                handleClose={() => {
                  setInfoAllergens(false);
                }}
                setDisplay={setInfoAllergens}
                content={
                  <Row
                    className=""
                    style={{
                      maxHeight: allergenData?.length > 4 && "270px",
                      overflowY: allergenData?.length > 4 && "scroll"
                    }}>
                    {allergenData?.map((allrg) => {
                      return (
                        <Col lg="6" md="6" sm="6" xs="6" key={uuidv4()}>
                          <div className="d-flex align-items-center mt-3">
                            <img
                              className="fs-3 p-1"
                              src={allrg?.icon}
                              style={{
                                borderRadius: "20px"
                              }}
                              width={40}
                              alt="icon"
                            />
                            <span className="text-dark fs-6 mx-2">{allrg?.title}</span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                }
                closeEvent={() => {
                  setInfoAllergens(false);
                }}
              />

              {group && groups && groups.map((category, catIdx) => {

                let content = null;

                // Choose the content to render based on category
                switch (category) {
                  case 'Special':
                    if (categoryIs.special) {
                      content = (
                        <SpecialSection
                          categoryIs={categoryIs}
                          specialsItem={specialsItem}
                          page_theme={page_theme}
                          swiping={swiping}
                          testImg={testImg}
                          setTestImgIndex={setTestImgIndex}
                          dispatch={dispatch}
                          busId={busId}
                          setShowCart={setShowCart}
                          setInfoAllergens={setInfoAllergens}
                          setAllergenData={setAllergenData}
                        />
                      );
                    }
                    break;
                  case 'Best Seller':
                    if (categoryIs.best_seller) {
                      content = (
                        <BestSeller
                          page_theme={page_theme}
                          settings={settings}
                          bestSellerData={bestSellerData}
                          setShowCart={setShowCart}
                          setTestImgInde={setTestImgIndex}
                          setInfoAllergens={setInfoAllergens}
                          setAllergenData={setAllergenData}
                          id={"Best Seller"}
                          swiping={swiping}
                        />
                      );
                    }
                    break;
                  case 'VEGAN':
                    if (categoryIs.vegan) {
                      content = (
                        <VeganVegetarian
                          page_theme={page_theme}
                          settings={settings}
                          veganItems={veganItems}
                          setShowCart={setShowCart}
                          setTestImgInde={setTestImgIndex}
                          setInfoAllergens={setInfoAllergens}
                          setAllergenData={setAllergenData}
                          id={"VEGAN"}
                          swiping={swiping}
                        />
                      );
                    }
                    break;
                  default:
                    content = loading ? (
                      <div
                        className={`w-100 text-center ${categoryIs.vegan || categoryIs.sepcial || categoryIs.best_seller ? "mt-5 pt-4" : null}`}>
                        <span
                          className="spinner-border spinner-border-lg text-danger text-center"
                          role="status"
                          aria-hidden="true">
                        </span>
                      </div>
                    ) : (
                      <div className="mt-3 w-100 gx-0 gy-0" id={`${category}`} key={uuidv4()}>
                        <Swiper
                          cssMode={true}
                          navigation={true}
                          mousewheel={true}
                          keyboard={true}
                          modules={[Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow]}
                          className="mySwiper"
                          slidesPerView={2.5}
                          spaceBetween={0}
                          coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 0,
                          }}
                        >

                          {

                            checkGroup(category).map((data) => {
                              return (
                                // <Carousel.Item className="">
                                <SwiperSlide key={uuidv4()}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                  className="dish-wrapper">


                                  <h1
                                    className={`menu-itemName d-flex justify-content-center  mb-2 ps-1 pe-1 font-${page_theme?.font_family_menu_item_title} fs-${page_theme?.font_size_menu_item_title}`}
                                    style={{
                                      color: page_theme?.primary_menu_item_title_color
                                    }}>
                                    {/* {window.innerWidth <= 500 &&
                                  window.innerHeight <= 900
                                    ? trimItemName(data?.name)
                                    : data?.name} */}
                                    {data?.name}
                                  </h1>
                                  <div className=" dish-img-container">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={data?.image || testImg[0]}
                                      className="dish-img1"
                                      alt="menu-item"
                                      onClick={() => {
                                        if (!swiping) {
                                          dispatch(
                                            SetCartDataAction(
                                              data
                                              //  ingredients: stableIngredients
                                            )
                                          );

                                          dispatch(
                                            loadDescription({
                                              sku: data.sku,
                                              blId: busId
                                            })
                                          );

                                          dispatch(Sync_Carts(data));
                                          setShowCart(true);
                                          dispatch(
                                            getSingleIngredients({
                                              sku: data.sku,
                                              blId: busId
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <OrderDetails
                                    page_theme={page_theme}
                                    data={data}
                                    setInfoAllergens={setInfoAllergens}
                                    setAllergenData={setAllergenData}
                                  />
                                </SwiperSlide>
                                // </Carousel.Item>
                              );
                            })}
                          {/* </Carousel> */}
                        </Swiper>
                      </div>
                    );
                }

                return (
                  <div key={catIdx}>
                    <Row className=" mt-4 gy-0 gx-0">
                      {category !== "Special" &&
                        category !== "Best Seller" &&
                        category !== "VEGAN" &&
                        allGroups?.find((group)=> group.group === category)
                            ? (
                            <div className="sectionHdng">
                              <div
                                  className={`hdngflex`}
                                  style={{ color: page_theme?.primary_title_general_color }}>
                                <h1
                                    className={`font-${page_theme?.font_family_general_title} fs-${page_theme?.font_size_general_title}`}
                                    style={{ color: page_theme?.primary_title_general_color }}>
                                  {category?.toUpperCase()}
                                </h1>
                              </div>
                              <div className="hdngWrap">
                                <div
                                    className="hdnglineblue"
                                    style={{ backgroundColor: page_theme?.primary_line_general_color }}>
                                </div>
                                <img
                                    className="leafsImg"
                                    src={checkGroupImg(category)?.substring(1, checkGroupImg(category)?.length - 1)}
                                    alt="" />
                              </div>
                            <img
                              className="leafsImg"
                              src={checkGroupImg(category)?.substring(1, checkGroupImg(category)?.length - 1)}
                              alt="" />
                          </div>
                      ) : null}
                    </Row>
                    {content}
                  </div>
                );
              })}

            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const FoodMenu = React.memo(Menus);
